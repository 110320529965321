/* eslint-disable @typescript-eslint/member-ordering */
import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TermsAndConditionsDialogComponent } from '@app/core/modules/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { ROUTE_MAIN_PATHS } from '@app/pages/pages.routes.paths';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { AlertLevel, CookieKey } from '@skyfit/core-data-library';
import { AaptivAuthService } from '@skyfit/core-web-library/aaptiv-services/authenticaton';
import { CookieService } from '@skyfit/core-web-library/services/cookie-service';
import { Subscription } from 'rxjs';
import { KeyCodes } from 'src/models/keyCodes';
import { ROUTE_AUTHENTICATION_PATHS } from '../authentication.routes.paths';

export enum InputType {
  text = 'text',
  password = 'password',
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('emailInput') emailInput: ElementRef;
  @ViewChild('passwordInput', { read: ElementRef }) passwordInput: ElementRef;
  @ViewChild('signInButton', { read: ElementRef }) signInButton: ElementRef;
  @ViewChildren('focusable') focusable: QueryList<'focusable'>;

  buttonClasses = ['sign-in-button'];
  emailError: boolean;
  errorMessage: string;
  keyboardIsOpen: boolean;
  qrWidth = 50;
  showEmailField = true;
  showPassword: boolean;
  signInForm: FormGroup;

  // focus vars
  activeNode: Element;
  nodes: HTMLCollectionOf<HTMLElement>;
  nodeHasBeenFocused: boolean;
  currentNodeIndex: number;

  // theme: Theme;

  dialogRef: MatDialogRef<TermsAndConditionsDialogComponent>;
  docWidth: number;
  isSubmitted = false;
  hasEmailError = false;
  hasFailed = false;
  hasNetworkError = false;
  hasPasswordError = false;
  isLoading = false;
  isLockedOut = false;
  isMobile: boolean;
  passwordInputType = 'password';
  termsDialogIsOpen: boolean;

  readonly registeredKeyCodes = [KeyCodes.arrowDown, KeyCodes.arrowUp];
  readonly qrUrl = 'https://aaptiv.com/get-started';
  readonly alertLevelError = AlertLevel.error;

  private _subscriptions: Subscription;

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private _aaptivAuthService: AaptivAuthService,
    private _changeDetector: ChangeDetectorRef,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _cookieService: CookieService,
    private _router: Router
  ) {
    this._subscriptions = new Subscription();
    this.initForm();
    this.docWidth = this._doc.defaultView.innerWidth;
  }

  ngOnInit(): void {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard?.addListener('keyboardWillShow', () => {
        this.keyboardIsOpen = true;
        this._changeDetector.detectChanges();
      });
      Keyboard?.addListener('keyboardWillHide', () => {
        this.keyboardIsOpen = false;
        this._changeDetector.detectChanges();
      });
    }
  }

  ngAfterViewInit(): void {
    this.emailInput?.nativeElement?.focus();
    this.nodes = this._doc.getElementsByClassName(
      'focusable'
    ) as HTMLCollectionOf<HTMLElement>;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  canDeactivate(): boolean {
    if (this.termsDialogIsOpen) {
      this.dialogRef.close();
      return false;
    } else {
      return true;
    }
  }

  onGoBack(event): void {
    event.preventDefault();
    if (!this.showEmailField) {
      this.showEmailField = true;
      this.currentNodeIndex = 0;
      setTimeout(() => {
        this.nodes.item(this.currentNodeIndex).focus();
      }, 100);
    }
  }

  onClickNext(): void {
    if (this.signInForm.get('email').valid) {
      this.showEmailField = false;
      this.emailError = false;
      // this.currentNodeIndex = 0;
      setTimeout(() => {
        this.passwordInput?.nativeElement?.focus();
      }, 100);
    } else {
      this.emailError = true;
    }
  }

  onSoftKeyboardEnter(event): void {
    event.preventDefault();
    Keyboard.hide();
    setTimeout(() => {
      this.signInButton.nativeElement.focus();
    }, 200);
  }

  // need to bind "this"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyPress = (event: any): void => {
    if (!this.termsDialogIsOpen) {
      this.nodeHasBeenFocused = !!this._doc.activeElement;
      if (
        !this.nodeHasBeenFocused &&
        (event.code === KeyCodes.arrowDown || event.code === KeyCodes.arrowUp)
      ) {
        this.nodes.item(0).focus();
        this.currentNodeIndex = 0;
        this.nodeHasBeenFocused = true;
      } else {
        this.activeNode = this._doc.activeElement;
        this.currentNodeIndex = Array.from(this.nodes).findIndex(
          (node) => node === this.activeNode
        );
        this.nodeHasBeenFocused = true;

        if (event.code === KeyCodes.arrowUp) {
          this.onArrowUp();
        }
        if (event.code === KeyCodes.arrowDown) {
          this.onArrowDown();
        }
      }
    }
  };

  onArrowUp(): void {
    if (this.currentNodeIndex !== 0) {
      --this.currentNodeIndex;
      this.nodes.item(this.currentNodeIndex).focus();
    }
  }

  onArrowDown(): void {
    if (this.currentNodeIndex < this.nodes.length) {
      this.currentNodeIndex += 1;
      this.nodes?.item(this.currentNodeIndex)?.focus();
    } else if (this.currentNodeIndex !== this.nodes.length - 1) {
      this.currentNodeIndex = this.nodes.length - 1;
      this.nodes.item(this.currentNodeIndex).focus();
    }
  }

  onArrowRight(): void {
    this.currentNodeIndex += 1;
    this.nodes?.item(this.currentNodeIndex)?.focus();
  }

  onArrowLeft(): void {
    this.currentNodeIndex -= 1;
    this.nodes?.item(this.currentNodeIndex)?.focus();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBackButtonClick(event: any): void {
    if (event.code === KeyCodes.arrowUp) {
      this.onArrowUp();
    }
    if (event.code === KeyCodes.arrowRight) {
      this.onArrowRight();
    }
    if (event.code === KeyCodes.arrowDown) {
      this.currentNodeIndex = 4;
      this.nodes?.item(this.currentNodeIndex)?.focus();
    }
    if (event.code === KeyCodes.enter) {
      this.onGoBack(event);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSignInButtonClick(event: any): void {
    if (event.code === KeyCodes.arrowUp) {
      this.currentNodeIndex = 1;
      this.nodes?.item(this.currentNodeIndex)?.focus();
    }
    if (event.code === KeyCodes.arrowLeft) {
      this.onArrowLeft();
    }
    if (event.code === KeyCodes.arrowDown) {
      this.onArrowDown();
    }
    if (event.code === KeyCodes.enter) {
      this.onSubmit();
    }
  }

  onOpenTerms(): void {
    this.termsDialogIsOpen = true;
    this.dialogRef = this._dialog.open(TermsAndConditionsDialogComponent, {
      panelClass: 'terms-dialog',
      closeOnNavigation: false,
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.termsDialogIsOpen = false;
    });
  }

  onSubmit(): void {
    const { email, password } = this.signInForm?.value;

    this.hasEmailError = false;
    this.hasFailed = false;
    this.hasPasswordError = false;
    this.hasNetworkError = false;

    // Make sure authForm values are valid
    if (this.signInForm.invalid) {
      if (!email) {
        this.hasEmailError = true;
      }

      if (!password) {
        this.hasPasswordError = true;
      }
      return;
    }

    this.isLoading = true;
    this._subscriptions.add(
      this._aaptivAuthService.login(email, password).subscribe({
        next: (res) => {
          if (res.error) {
            this.isLoading = false;
            this.onAuthError(res.error);
          } else {
            const currentUser = res;
            if (currentUser.id && currentUser.secret) {
              this._cookieService.set(CookieKey.jwt, currentUser.secret);
              this._aaptivAuthService.setCurrentUser(currentUser);
              // assuming access for all aaptive users for now
              this._router.navigateByUrl(ROUTE_MAIN_PATHS.home);
              this.isLoading = false;
            } else {
              // TODO: replace with better error that this is a problem ith the user?
              this.onAuthError('wrong-password');
              this.isLoading = false;
            }
          }
        },
        error: (error) => {
          const errorCode = error; // auth error set up needs to be re-worked, temp fix for now.
          if (errorCode === 'network-request-failed') {
            this.hasNetworkError = true;
          } else {
            this.onAuthError(errorCode);
          }
          this.isLoading = false;
          this._changeDetector.detectChanges();
        },
      })
    );
  }

  onAuthError(errorCode: string): void {
    if (errorCode) {
      this.errorMessage = errorCode;
      this.hasFailed = true;
      this._changeDetector.detectChanges();
    }
  }

  onClickSignUp(): void {
    this._router.navigateByUrl(ROUTE_AUTHENTICATION_PATHS.signUp);
  }

  onClickForgotPassword(): void {
    this._router.navigateByUrl(ROUTE_AUTHENTICATION_PATHS.forgotPassword);
  }

  onClickPasswordVisibility(event): void {
    event.preventDefault();
    this.showPassword = !this.showPassword;
    this.passwordInputType = this.showPassword
      ? InputType.text
      : InputType.password;
  }

  private initForm(): void {
    this.signInForm = this._formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', Validators.required],
    });
  }
}

/* eslint-disable change-detection-strategy/on-push */
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { PlatformService } from '@skyfit/core-web-library/aaptiv-services/platform';

import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/_current/environment';
import { filter, map } from 'rxjs';
import { AppStateService } from 'src/services/app-state/app-state.service';
import { ConfirmationDialogComponent } from './core/modules/confirmation-dialog/confirmation-dialog.component';
import { ROUTE_MAIN_PATHS } from './pages/pages.routes.paths';

declare const gtag: (command: string, id: string, config?: object) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    private _platformService: PlatformService,
    private _router: Router,
    private _stateService: AppStateService,
    private _title: Title,
    private _zone: NgZone
  ) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsTrackingId, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'page-path': event.urlAfterRedirects,
        });
      }
    });
  }
  ngOnInit(): void {
    this._platformService.setPlatform();
    this.setPageTitle();
    App.addListener('pause', () => {
      this._router.navigate([ROUTE_MAIN_PATHS.home]);
    });
    App.addListener('resume', () => {
      this._stateService.onAppResume();
    });
    App.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        this.openExitConfirmation();
      } else {
        window.history.back();
      }
    });
  }

  openExitConfirmation(): void {
    const dialog = this._zone.run(() =>
      this._matDialog.open(ConfirmationDialogComponent, {
        width: '30vw',
        height: '30vh',
        panelClass: 'dialog-container',
        data: {
          messageText: 'Are you sure you want to exit Aaptiv TV?',
          negativeButtonText: 'Cancel',
          affirmativeButtonText: 'Exit',
        },
      })
    );
    dialog.afterClosed().subscribe({
      next: (res) => {
        if (res) {
          App.exitApp();
        }
      },
    });
  }
  setPageTitle(): void {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this._activatedRoute?.firstChild;

          while (child?.firstChild) {
            child = child.firstChild;
          }
          const title = child?.snapshot?.data?.title;
          if (title) {
            return `Aaptiv TV - ${title}`;
          } else {
            return 'Aaptiv TV';
          }
        })
      )
      .subscribe((title) => {
        this._title.setTitle(title);
      });
  }
}

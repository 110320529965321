import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KeyCodes } from 'src/models/keyCodes';

export enum ConfirmationOptions {
  affirmative = 'affirmative',
  negative = 'negative',
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('negativeButton', { read: ElementRef }) negativeButton: ElementRef;
  @ViewChild('affirmativeButton', { read: ElementRef })
  affirmativeButton: ElementRef;
  affirmativeButtonText: string;
  messageText: string;
  negativeButtonText: string;
  clickListener: () => void;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public data: any
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.affirmativeButtonText = this.data?.affirmativeButtonText;
      this.messageText = this.data?.messageText;
      this.negativeButtonText = this.data?.negativeButtonText;
    }
    this._changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
    const optionsBody =
      this._elementRef.nativeElement.querySelector('#optionsBody');
    this.clickListener = this._renderer.listen(
      optionsBody,
      'keydown',
      (event) => {
        switch (event.code || event.key) {
          case KeyCodes.arrowLeft:
            event.preventDefault();
            this.onArrowLeft(event);
            break;
          case KeyCodes.arrowRight:
            event.preventDefault();
            this.onArrowRight(event);
            break;
        }
      }
    );
    this._changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.clickListener();
  }

  onArrowLeft(event): void {
    const buttonClicked = event.target.id;
    if (buttonClicked === ConfirmationOptions.affirmative) {
      this.negativeButton.nativeElement.focus();
    }
  }

  onArrowRight(event): void {
    const buttonClicked = event.target.id;
    if (buttonClicked === ConfirmationOptions.negative) {
      this.affirmativeButton.nativeElement.focus();
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
